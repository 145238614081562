import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Banner from "../HomePage/Banner";
import AboutUs from '../HomePage/AboutUs';
import OurBlog from "./OurBlog";
import WeProvide from "./WeProvide";
import ChooseUs from "./ChooseUs";
import HomeFAQ from "./HomeFAQ";
import Contact from "./Contact/Contact";

export default function Home() {
  const Index = () => {
    const { section } = useParams(); // Get the route parameter
    const sectionRef = useRef(null);
    useEffect(() => {
      if (section) {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.scrollTo(0, 0); // Scroll to top if no section specified
      }
    }, [section]);

    return null;
  };

  return (
    <>
      <Index />
      <section id='home'>
        <Banner />
        <AboutUs />
        <ChooseUs />
      </section>
      <section id='clients'>
        <WeProvide />
      </section>
      <section id='faq'>
        <HomeFAQ />
        <OurBlog />
        <Contact />
      </section>
    </>
  );
}
