import React from "react";
import { Routes, Route } from "react-router-dom";
import { Contact } from "../components/pages/Contact/Contact";
import Home from "../components/pages/HomePage/Home";
import Values from "../components/pages/Service/Values";
// import FAQ from "../components/pages/FAQ/FAQ";
import AboutUsMenu from "../components/pages/AboutUs/AboutUsMenu";
import NucleusBlog from "../components/pages/Blog/NucleusBlog";
// import { BlogPage } from './../components/pages/Blog/BlogPage';
import NucleusBlogPage from "../components/pages/Blog/NucleusBlogPage";
import Products from "../components/pages/Portfolio/Products";
import InHouse from "../components/pages/Portfolio/InHouse";
import Error from "../components/pages/HomePage/error";



export default function Index() {
  
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home/:section" element={<Home/>} />
        <Route path="/values" element={<Values />} />
        <Route path="/Products" element={<Products />} />
        {/* <Route path="/faq" element={<FAQ />} /> */}
        <Route path="/blog" element={<NucleusBlog />} />
        <Route path="/blogpage/:id" element={<NucleusBlogPage />} />
        {/* <Route path="/blogpage1" element={<BlogPage />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutus" element={<AboutUsMenu />} />
        <Route path="/inhouse" element={<InHouse/>} />
        <Route path="/error" element={<Error/>} />
        <Route path="/*" element={<Error/>}/>
      </Routes>
    </div>
  );
}
