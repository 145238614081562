import React from "react";


export default function Contact(){
    return(
        <>
        <section className="row justify-content-center mt-60 mt-1 mb-5">
            <div className="container">
                <div className="row text-center">
                    <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                    <p className="text-muted para-desc mx-auto px-4">We're here to help! Reach out to us for expert opinion and personalized assistance.</p>
                    <div className="spacing"></div>
                        <div className="col-lg-4 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0" >
                            <div className="card features feature-primary text-center border-0">
                                <div className="text-center mx-auto">
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <div className="card-body p-0 mt-3">
                                    <h5>Contact Us</h5>
                                    <h6>103, Nucleus House, Berkhedi Road, Jahangirad, Near Extol College, Bhopal M.P.</h6>
                                </div>
                            </div>
                        </div>
                    <div className="col-lg-4 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0" >
                        <div className="card features feature-primary text-center border-0">
                            <div className="text-center mx-auto">
                                <i className="fas fa-phone-alt"></i>
                            </div>
                            <div className="card-body p-0 mt-3">
                                <h5>Call Us</h5>
                                <h6>+91 9425010238</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0" >
                        <div className="card features feature-primary text-center border-0">
                            <div className="text-center mx-auto">
                                <i className="fas fa-envelope"></i>
                            </div>
                            <div className="card-body p-0 mt-3">
                                <h5>Mail Us</h5>
                                <h6>vivek7b@yahoo.com</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1541.2546897284515!2d77.41483265969326!3d23.252470637198776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c4282901ef83d%3A0x2a5cfe465fd05604!2sNUCLEUS%20ELECTRO%20ENTERPRISES!5e0!3m2!1sen!2sin!4v1710147635425!5m2!1sen!2sin" 
                                width="100%" 
                                height="450px" 
                                style={{ border: '0' }} 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Google Maps"
                            ></iframe>
        </>
    )
}
