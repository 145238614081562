import { useState } from "react";
import "../../../theme/css/Portfolio.css";
import { Card } from "react-bootstrap";
// import Portfolio1 from "../../../assets/Images/Portfolio/Portfolio1.png";
// import Portfolio2 from "../../../assets/Images/Portfolio/Portfolio2.png";
// import Portfolio3 from "../../../assets/Images/Portfolio/Portfolio3.png";
// import Portfolio4 from "../../../assets/Images/Portfolio/Portfolio4.png";
// import Portfolio5 from "../../../assets/Images/Portfolio/Portfolio5.png";
// import Portfolio6 from "../../../assets/Images/Portfolio/Portfolio6.png";
// import Portfolio7 from "../../../assets/Images/Portfolio/Portfolio7.png";
// import Portfolio8 from "../../../assets/Images/Portfolio/Portfolio8.png";
// import Portfolio9 from "../../../assets/Images/Portfolio/Portfolio9.png";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Row, Col } from "react-bootstrap";
import { ProductData } from "../HomePage/ProductData";
function NucleusProducts() {
  useEffect(() => {
    Aos.init({ duration: 3500 });
  }, []);
  const [toggleState, setToggleState] = useState(3);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  

  return (
    <>
    <div className="container portfolio">
      <div className="bloc-tabs ">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          Voltage Stabilizer
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          Power Supply
        </button>
        <button
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          UPS Inverter
        </button>
        <button
          className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(4)}
        >
          Lights and More
        </button>
      </div>
    </div>
    <div className="container portfolio">

      <div className="content-tabs">
          <div className="beffect">
            <Row>
              {ProductData.map((products) =>(
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div key={products.id}>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={products.image} />
                  </div>
                  <Card.Body> 
                    <Card.Title>{products.name}</Card.Title>
                    <Card.Text>
                      {products.desc}
                    </Card.Text>
                  </Card.Body>
                  </div>
                </Card>
              </Col>
              ))
              }
            </Row>
          </div>
      </div>
    </div>
    </>
  );
}

export default NucleusProducts;
