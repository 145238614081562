import React, { Component } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "../../../../node_modules/slick-carousel/slick/slick";
// import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import BrandLogo1 from "../../../assets/Images/BrandLogo/ClientLogo/ApexHospital.jpg";
import BrandLogo2 from "../../../assets/Images/BrandLogo/ClientLogo/BHELLogo.png";
import BrandLogo3 from "../../../assets/Images/BrandLogo/ClientLogo/Bansal.png";
import BrandLogo4 from "../../../assets/Images/BrandLogo/ClientLogo/BendJoints.png";
import BrandLogo5 from "../../../assets/Images/BrandLogo/ClientLogo/HEG.png";
import BrandLogo6 from "../../../assets/Images/BrandLogo/ClientLogo/IISEL.png";
import BrandLogo7 from "../../../assets/Images/BrandLogo/ClientLogo/JehanNuma.avif";
import BrandLogo8 from "../../../assets/Images/BrandLogo/ClientLogo/LBS.jpg";
import BrandLogo9 from "../../../assets/Images/BrandLogo/ClientLogo/SevaSadan.png";
import BrandLogo10 from "../../../assets/Images/BrandLogo/ClientLogo/Vindhyachal.png";
import BrandLogo11 from "../../../assets/Images/BrandLogo/ClientLogo/Weartech.webp";

export default class Brandlogo extends Component {
  render() {
    const { logos, settings } = this.props;
    const brandLogos = [
      BrandLogo1,
      BrandLogo2,
      BrandLogo3,
      BrandLogo4,
      BrandLogo5,
      BrandLogo6,
      BrandLogo7,
      BrandLogo8,
      BrandLogo9,
      BrandLogo10,
      BrandLogo11
    ];

    // Default settings for the slider
    const defaultSettings = {
      autoplay: true,
      autoplaySpeed: 100,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrow: false,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 5,
          }
        },
        { 
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    };

    // Merge default settings with props passed from parent component
    const mergedSettings = { ...defaultSettings, ...settings };

    // Randomly shuffle the brand logos
    const shuffledBrandLogos = logos.sort(() => Math.random() - 0.5);

    return (
      <div className="container">
        <div className="brandLogoItem">
          <Slider {...mergedSettings}>
            {shuffledBrandLogos.map((logo, index) => (
              <div key={index}>
                <img className="img-fluid" src={logo} alt="BrandLogo" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

// export default class Brandlogo extends Component {
//   render() {
//     autoplay: true,
//       autoplaySpeed: 100,
//       dots: false,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 6,
//       slidesToScroll: 1,
//       arrow: false,
//       responsive: [
//         {
//           breakpoint: 1600,
//           settings: {
//             slidesToShow: 5,
//           }
//         },
//         {
//           breakpoint: 900,
//           settings: {
//             slidesToShow: 5,
//           }
//         },
//         { 
//           breakpoint: 800,
//           settings: {
//             slidesToShow: 3,
//           }
//         },
//         {
//           breakpoint: 600,
//           settings: {
//             slidesToShow: 3,
//           }
//         },
//         {
//           breakpoint: 480,
//           settings: {
//             slidesToShow: 2,
//           }
//         }
//       ]
//     }
//     };
//     const brandLogos = [
//       BrandLogo1,
//       BrandLogo2,
//       BrandLogo3,
//       BrandLogo4,
//       BrandLogo5,
//       BrandLogo6,
//       BrandLogo7,
//       BrandLogo8,
//       BrandLogo9,
//       BrandLogo10,
//       BrandLogo11
//     ];

//     const shuffledBrandLogos = brandLogos.sort(() => Math.random() - 0.5);

//     return (
//       <>
//         <div className="container">
//           <div className="brandLogoItem">
//           <Slider {...settings}>
//           {shuffledBrandLogos.map((logo, index) => (
//                 <div key={index}>
//                   <img className="img-fluid" src={logo} alt="BrandLogo" />
//                 </div>
//               ))}
//           </Slider>
//           </div>
//         </div>
//       </>
//     );
//   }
// }
