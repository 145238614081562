import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import Brandlogo from "./Brandlogo";
import Clients from "../../../assets/Images/AboutUs/clients.svg"
import BrandLogo1 from "../../../assets/Images/BrandLogo/ClientLogo/ApexHospital.jpg";
import BrandLogo2 from "../../../assets/Images/BrandLogo/ClientLogo/BHELLogo.png";
import BrandLogo3 from "../../../assets/Images/BrandLogo/ClientLogo/Bansal.png";
import BrandLogo4 from "../../../assets/Images/BrandLogo/ClientLogo/BendJoints.png";
import BrandLogo5 from "../../../assets/Images/BrandLogo/ClientLogo/HEG.png";
import BrandLogo6 from "../../../assets/Images/BrandLogo/ClientLogo/IISEL.png";
import BrandLogo7 from "../../../assets/Images/BrandLogo/ClientLogo/JehanNuma.avif";
import BrandLogo8 from "../../../assets/Images/BrandLogo/ClientLogo/LBS.jpg";
import BrandLogo9 from "../../../assets/Images/BrandLogo/ClientLogo/SevaSadan.png";
import BrandLogo10 from "../../../assets/Images/BrandLogo/ClientLogo/Vindhyachal.png";
import BrandLogo11 from "../../../assets/Images/BrandLogo/ClientLogo/Weartech.webp";

// import { MdDashboardCustomize } from "react-icons/md";

// import { FaCircle } from "react-icons/fa"; // Import suitable bullet point icon
// import "./WeProvide.css"; // Import CSS file for additional styling if needed

const namesList = [
  "BMHRC Hospital, Bhopal",
  "Charak Hospital, Bhopal",
  "Chirayu Hospital, Bhopal",
  "Agrawal Hospital, Bhopal",
  "Apex Hospital, Bhopal",
  "Seva Sadan Hospital, Bhopal",
  "LBS Hospital, Bhopal",
  "Paras Life, Bhopal",
  "Bansal Industries, Bhopal",
  "Bend Joints, Bhopal",
  "BHEL, Bhopal",
  "Enterprising Engineer, Bhopal",
  "Weartech Engineers, Bhopal",
  "Inox Air Products Ltd, Bhopal",
  "Surabh Metal, Bhopal",
  "HEG, Bhopal",
  "IISEL, Bhopal",
  "CMR Infrastructure Pvt Ltd, Bhopal",
  "Power Control, Bhopal",
  "Vindhyachal Distilleries Pvt Ltd, Bhopal",
  "Alka Physiotherapy Orthopedic Center, Bhopal",
  "Varenyam Motors Car, Bhopal",
  "Aempeb, Bhopal",
  "Techno Planners LLP , Bhopal",
  "Jehan Numa Palace Hotel, Bhopal",
  "PGH International Pvt, Bhopal",
  "Golf View Club & Resorts Pvt, Bhopal",
  "Dr. Agrawal, Bhopal",
  "PWD, Bhopal",
  "Venus Chetan Lift Pvt, Bhopal",
  "Durand  Contral, Bhopal",
  "Gap Enterprises, Bhopal",
];


export default function WeProvide() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 20,
    swipeToSlide: true,
    initialSlide: 0 // Start from the first slide
  };

  return (
    <div className="container">
      <div className="weProvide spacing">
            <div>
              <div className="titleFont">
                <h3>Trusted by 50+ companies</h3>
              </div>
              <h2 className="text-center" style={{ marginTop: "20px" }}>
                We offer the best Power Solutions to various sectors
              </h2>
              <p>
                Trusted by a diverse array of esteemed clients including
                leading hospitals, industrial giants, educational institutions,
                and hospitality establishments, we at Nucleus Electro
                Enterprises take immense pride in our widespread recognition.
                With a track record of serving over 50 companies across various
                sectors in Bhopal, our commitment to reliability, innovation,
                and superior service has earned us the trust of businesses big
                and small. Partner with us and experience the difference
                firsthand as we power your success story.
              </p>
            <h2 className="text-center spacing">Our Esteemed Clients</h2>
            </div>
        <Row className="weProvideRow spacing">
        <Brandlogo
          logos={[BrandLogo1, BrandLogo2, BrandLogo3, BrandLogo4, BrandLogo5, BrandLogo6, BrandLogo7, BrandLogo8, BrandLogo9, BrandLogo10, BrandLogo11]}
          settings={{
            autoplaySpeed: 200,
            slidesToShow: 4,
            rtl: true
          }}
        />
            
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <div className="container">
          <img src={Clients}></img>
        </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="">
        <div className="container">
          <div className="spacing_companies text-start">
            <Slider {...sliderSettings}>
              {namesList.map((name, index) => (
                <div key={index} className="company-item">
                  <span>{name}</span>
                </div>
              ))}
            </Slider>
          </div>
        </div>
          </Col>
          <Brandlogo
          logos={[BrandLogo11, BrandLogo10, BrandLogo9, BrandLogo8, BrandLogo7, BrandLogo6, BrandLogo5, BrandLogo4, BrandLogo3, BrandLogo2, BrandLogo1]}
          settings={{
            autoplaySpeed: 500,
            slidesToShow: 4,
            rtl: false
          }}
        />
        </Row>
      </div>
    </div>
  );
}