import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import img1 from "../../../assets/Images/OurBestServices/solution.png";
import img2 from "../../../assets/Images/OurBestServices/innovation.png";
import img3 from "../../../assets/Images/OurBestServices/assurance.png";
import img4 from "../../../assets/Images/OurBestServices/community.png";

const OurPricing = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="container">
      <div className="spacing">
        <div className="titleFont text-center">
          <h3>Our Core Values</h3>
          <h2>Upholding Excellence</h2>
        </div>
        <div className="ourPricingCard">
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                className="pricingCard"
                data-aos="flip-left"
              >
                <div className="pricingColorBox text-center">
                  <img src={img1} alt="pricing1" />
                  <h3>Customized Solutions</h3>
                </div>
                <div className="pricingSpacingBox">
                  <ul className="list-unstyled pricingBoxList">
                    <li>
                      <span>
                        Unlike one-size-fits-all approaches, we understand
                        that each client's power needs are unique. That's why
                        we offer customized solutions tailored to specific
                        requirements, ensuring optimal performance and
                        efficiency.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                className="pricingCard"
                data-aos="flip-left"
              >
                <div className="pricingColorBox text-center">
                  <img src={img2} alt="pricing2" />
                  <h3>Innovative Technology</h3>
                </div>
                <div className="pricingSpacingBox">
                  <ul className="list-unstyled pricingBoxList">
                    <li>
                      <span>
                        We stay ahead of the curve by embracing the latest
                        technological advancements in the feild. From
                        advanced voltage stabilization techniques to
                        energy-efficient power supplies, our solutions
                        incorporate innovative features.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                className="pricingCard"
                data-aos="flip-left"
              >
                <div className="pricingColorBox text-center">
                  <img src={img3} alt="pricing1" />
                  <h3>Exceptional Quality Assurance</h3>
                </div>
                <div className="pricingSpacingBox">
                  <ul className="list-unstyled pricingBoxList">
                    <li>
                      <span>
                      Quality is at the core of everything we do. Our rigorous quality assurance processes, from sourcing components to final testing, ensure that every product meets the highest standards of durability, reliability, and safety. With Nucleus Electro Enterprises, you can trust in products built to last.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <div
                className="pricingCard"
                data-aos="flip-left"
              >
                <div className="pricingColorBox text-center">
                  <img src={img4} alt="pricing2" />
                  <h3>Customer-Centric Approach</h3>
                </div>
                <div className="pricingSpacingBox">
                  <ul className="list-unstyled pricingBoxList">
                    <li>
                      <span>
                      We go above and beyond to understand our clients' needs and provide personalized service and support every step of the way. Whether it's product recommendations, installation assistance, or after-sales service, we are committed to ensuring a seamless experience for our customers.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OurPricing;
