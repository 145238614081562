import React from "react";
import FAQ from "../../../assets/Images/Faq/FAQ.jpg";
import { Row, Col, Accordion } from "react-bootstrap";
function HomeFAQ() {
  return (
    <>
      <div className="container">
          <div className="spacing">
            <div className="titleFont">
              <h3> Frequently Asked Questions </h3>
              <h2>You’ve got questions and we’ve got answers</h2>
            </div>
        <Row className="homeFAQRow">
          <Col xl={6} lg={6} md={6}>
            <div className="homeFAQ">
            <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                What is a voltage stabilizer, and how does it work?
                </Accordion.Header>
                <Accordion.Body>
                A voltage stabilizer is an electrical device designed to maintain a consistent voltage level within a specified range, regardless of fluctuations in the input voltage. It works by continuously monitoring the incoming voltage and making automatic adjustments to stabilize the output voltage, ensuring that connected equipment receives a steady and reliable power supply.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                Why is voltage stabilization important for electrical systems?
                </Accordion.Header>
                <Accordion.Body>
                Voltage stabilization is crucial for electrical systems because it helps protect sensitive equipment from damage caused by voltage fluctuations. By maintaining a stable voltage supply, voltage stabilizers ensure the smooth operation and longevity of electrical devices, reducing the risk of malfunctions, data loss, and premature failure.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                What are the benefits of using a voltage stabilizer?
                </Accordion.Header>
                <Accordion.Body>
                The benefits of using a voltage stabilizer include:
                <ul>
                  <li>Protection against voltage fluctuations and power surges</li>
                  <li>Improved performance and reliability of connected equipment</li>
                  <li>Energy efficiency by optimizing voltage levels</li>
                  <li>Extension of equipment lifespan and reduction of maintenance costs</li>
                </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                What factors should I consider when choosing a voltage stabilizer?
                </Accordion.Header>
                <Accordion.Body>
                Factors to consider when choosing a voltage stabilizer include:
                <ul>
                  <li>Input voltage range and output voltage stability</li>
                  <li>Maximum load capacity and overload protection</li>
                  <li>Type of load (resistive, inductive, or capacitive)</li>
                  <li>Application environment (residential, commercial, or industrial)</li>
                  <li>Additional features such as surge protection, noise filtering, and automatic voltage regulation (AVR)</li>
                </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                How often should a voltage stabilizer be serviced or maintained?
                </Accordion.Header>
                <Accordion.Body>
                Voltage stabilizers should undergo regular maintenance and servicing as recommended by the manufacturer or an experienced technician. Typically, routine inspections and maintenance checks are conducted annually to ensure optimal performance and reliability.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                Can a voltage stabilizer be installed for both residential and industrial applications?
                </Accordion.Header>
                <Accordion.Body>
                Yes, voltage stabilizers are suitable for various applications, including residential, commercial, and industrial settings. They are available in a range of sizes and configurations to meet the specific voltage stabilization requirements of different environments and loads.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                What is the difference between a voltage stabilizer and a UPS (Uninterruptible Power Supply)?
                </Accordion.Header>
                <Accordion.Body>
                While both voltage stabilizers and UPS systems provide power protection, they serve different purposes. A voltage stabilizer regulates voltage levels to maintain stability, whereas a UPS provides backup power during outages and ensures continuity of power supply, often incorporating voltage stabilization as well.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                How can I troubleshoot common issues with my voltage stabilizer?
                </Accordion.Header>
                <Accordion.Body>
                Common troubleshooting steps for voltage stabilizers include checking connections, inspecting for signs of damage or overheating, verifying input and output voltage levels, and consulting the user manual or contacting a qualified technician for assistance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                Do voltage stabilizers consume electricity even when the load is stable?
                </Accordion.Header>
                <Accordion.Body>
                Voltage stabilizers consume a small amount of electricity to operate their internal components and maintain voltage regulation. However, modern voltage stabilizers are designed to be energy-efficient, minimizing power consumption during stable load conditions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                Are voltage stabilizers compatible with renewable energy sources like solar power?
                </Accordion.Header>
                <Accordion.Body>
                Yes, voltage stabilizers are compatible with renewable energy sources such as solar power. They can be used to stabilize the voltage output from solar panels or other renewable energy systems, ensuring consistent and reliable power supply to connected equipment.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            </div>
            {/* <img className="img-fluid mx-auto" alt="AboutUs" src={FAQ} /> */}
          </Col>
          <Col xl={6} lg={6} md={6}>
            <div className="homeFAQ">
            <Accordion defaultActiveKey="">

              <Accordion.Item eventKey="10">
                <Accordion.Header>
                What is a UPS, and how does it work?
                </Accordion.Header>
                <Accordion.Body>
                A UPS (Uninterruptible Power Supply) is an electrical device that provides emergency backup power to connected equipment in the event of a power outage or voltage fluctuation. It typically consists of a battery, inverter, and automatic transfer switch to seamlessly switch to battery power when the main power source fails.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                Why is a UPS important for critical systems?
                </Accordion.Header>
                <Accordion.Body>
                A UPS is essential for critical systems such as computers, servers, and networking equipment because it ensures uninterrupted power supply, prevents data loss, and protects against equipment damage caused by power disturbances like outages, surges, and spikes.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                What are the different types of UPS systems available?
                </Accordion.Header>
                <Accordion.Body>
                UPS systems are categorized into three main types: offline/standby UPS, line-interactive UPS, and online/double-conversion UPS. Each type offers varying levels of protection and efficiency, catering to different application requirements and budgets.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                How long can a UPS provide backup power during an outage?
                </Accordion.Header>
                <Accordion.Body>
                The backup runtime of a UPS depends on factors such as the capacity of the battery, the power load being supported, and the efficiency of the UPS system. UPS models are typically rated for a certain amount of runtime at full load and longer durations at lighter loads.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header>
                Can a UPS be used for home or small office applications?
                </Accordion.Header>
                <Accordion.Body>
                Yes, UPS systems are available in a range of sizes and capacities suitable for home or small office applications. They can protect computers, home entertainment systems, routers, and other sensitive electronics from power disruptions and voltage irregularities.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header>
                What is a power supply unit (PSU), and what does it do?
                </Accordion.Header>
                <Accordion.Body>
                A power supply unit (PSU) is a device that converts electrical power from a source into usable power for electronic devices. It provides the necessary voltage, current, and regulation to power internal components such as processors, graphics cards, and peripherals in computers and other electronic devices.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>
                What are the different types of power supplies available?
                </Accordion.Header>
                <Accordion.Body>
                Power supplies come in various forms, including ATX power supplies for desktop computers, AC/DC adapters for laptops and small electronics, and DC/DC converters for industrial applications. Each type is designed to meet specific voltage and power requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header>
                How do I choose the right power supply for my device?
                </Accordion.Header>
                <Accordion.Body>
                When selecting a power supply, consider factors such as the power requirements of your device, the voltage and current ratings needed, the form factor and compatibility with your device's connectors, and any additional features such as modular cables or efficiency ratings.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18">
                <Accordion.Header>
                What is the efficiency rating of a power supply, and why is it important?
                </Accordion.Header>
                <Accordion.Body>
                The efficiency rating of a power supply indicates how effectively it converts input power into usable output power. Higher efficiency ratings mean less energy waste and lower operating costs over time. Look for power supplies with 80 PLUS certification or higher for optimal efficiency.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="19">
                <Accordion.Header>
                How do I troubleshoot power supply issues in my device?
                </Accordion.Header>
                <Accordion.Body>
                Common power supply issues include overheating, fan failure, voltage irregularities, and component failures. Troubleshooting steps may involve checking connections, inspecting for physical damage, testing output voltages, and replacing faulty components or the entire power supply unit if necessary.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </div>
          </Col>
        </Row>
      </div>
      </div>
    </>
  );
}

export default HomeFAQ;
