import product1 from '../../../assets/Images/Products/1.png';
import product2 from '../../../assets/Images/Products/2.png';
import product3 from '../../../assets/Images/Products/3.png';
import product4 from '../../../assets/Images/Products/4.png';
import product5 from '../../../assets/Images/Products/5.png';
import product6 from '../../../assets/Images/Products/6.png';
import product7 from '../../../assets/Images/Products/7.png';
import product8 from '../../../assets/Images/Products/8.png';
import product9 from '../../../assets/Images/Products/9.png';
import product10 from '../../../assets/Images/Products/10.png';
import product11 from '../../../assets/Images/Products/11.png';
import product12 from '../../../assets/Images/Products/12.png';
import product13 from '../../../assets/Images/Products/13.png';
import product14 from '../../../assets/Images/Products/14.png';
import product15 from '../../../assets/Images/Products/15.png';
import product16 from '../../../assets/Images/Products/16.png';
import product17 from '../../../assets/Images/Products/17.png';
import product18 from '../../../assets/Images/Products/18.png';
import product19 from '../../../assets/Images/Products/19.png';

export const ProductData= [
    {
        id: '1',
        name: 'Portable Digital Voltage Stabilizer',
        image: product1,
        desc: 'Portable Electric Auto Cut Digital Voltage Stabilizer With 170V To 270V Input And 230V Output Current: AC Volt (V)'
    },
    {
        id: '2',
        name: 'Voltage Stabilizer MC01',
        image: product2,
        desc: 'Single Phase Automatic Voltage Stabilizer MC01 With 170V To 270V Input And 230V Output Current: AC Volt (V)'
    },
    {
        id: '3',
        name: 'Portable Digital Voltage Stabilizer',
        image: product3,
        desc: 'Portable Electric Auto Cut Digital Voltage Stabilizer With 170V To 270V Input And 230V Output Current: AC Volt (V)'
    },
    {
        id: '4',
        name: 'Voltage Stabilizer VS05',
        image: product4,
        desc: 'Single Phase Automatic Voltage Stabilizer VS05 With 170V To 270V Input And 230V Output Current: AC'
    },
    {
        id: '5',
        name: 'Battery Charger BC10072NN',
        image: product5,
        desc: 'Metal Industrial Analog Type BC10072NN Battery Charger, 30V, 10 Amp With 50hz Frequency'
    },
    {
        id: '6',
        name: 'Voltage Stabilizer SRV20N',
        image: product6,
        desc: 'Single Phase Automatic Servo Voltage Stabilizer SRV20N, 170V To 270V Input And 230V Output Ambient Temperature: 0-45 deg Celsius'
    },
    {
        id: '7',
        name: 'Battery Charger BC5048',
        image: product7,
        desc: 'Metal Analog Type Electric BC5048 Battery Charger, 30V, 10 Amp With 50hz Frequency'
    },
    {
        id: '8',
        name: 'Servo Voltage Stabilizer - Analog',
        image: product8,
        desc: 'Analog Type Automatic Servo Voltage Stabilizer, 170V To 270V Input And 230V Output Current: AC Volt (V)'
    },
    {
        id: '9',
        name: 'Servo Voltage Stabilizer - Automatic',
        image: product9,
        desc: 'Single Phase Automatic Servo Voltage Stabilizer With Steel Body, 170V To 270V Input And 230V Output Current: AC Volt (V)'
    },
    {
        id: '10',
        name: 'Voltage Stabilizer - 3 Phase (Analog)',
        image: product10,
        desc: 'Analog Type Three Phase Air Cooled Servo Voltage Stabilizer, Input 170V To 270V And 230V Output Current: AC Volt (V)'
    },
    {
        id: '11',
        name: 'Voltage Stabilizer - 3 Phase (Automatic)',
        image: product11,
        desc: 'Three Phase Automatic Servo Voltage Stabilizer, 170V To 270V Input And 230V Output Ambient Temperature: 0-45 deg Celsius'
    },
    {
        id: '12',
        name: 'Voltage Stabilizer - 20KVA',
        image: product12,
        desc: 'Automatic 20 KVA Single Phase Servo Voltage Stabilizer, 170V To 270V Input And 230V Output Current: AC Volt (V)'
    },
    {
        id: '13',
        name: 'Led Street Light',
        image: product13,
        desc: 'Aluminum Frame Rectangle Warm White Led Street Light, 110V To 220V Input With Ip44 Protection'
    },
    {
        id: '14',
        name: 'Led Lights - Round',
        image: product14,
        desc: 'White Aluminum Body Round Led Lights With 5000-6500 K And Color Temperature And Ip65 Protection'
    },
    {
        id: '15',
        name: 'Led Lights - Square',
        image: product15,
        desc: 'Square Shape Cool White Led Lights, Ip65 Protection With Aluminum Body Application: Building Facade Wall Highlighting Outdoor Lighting'
    },
    {
        id: '16',
        name: 'UPS Inverter',
        image: product16,
        desc: 'Grey Single Phase UPS Inverter With 100 Ah To 220 Ah Battery Support, 10.8 Kg Weight'
    },
    {
        id: '17',
        name: 'Isolation Transformer - Standard Dry Type',
        image: product17,
        desc: 'Metal Industrial Is Standard Dry Type Or Air Cooled Isolation Transformer For Electrical And Electronic Equipment'
    },
    {
        id: '18',
        name: 'Battery Charger - Analog',
        image: product18,
        desc: 'Analog Type Electric 30V 10 Amp Battery Charger With 50hz Frequency For Industrial'
    },
    {
        id: '19',
        name: 'Sonic Automotive Battery',
        image: product19,
        desc: 'F1800-35l Sf Sonic Automotive Battery 35Ah, 5-10 Kg With 60 Months Warranty'
    },   
]