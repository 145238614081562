import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import OurPricing from "../HomePage/OurPricing";
import "../../../theme/css/Service.css";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";



export default function Values() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="otherPageBanner servicesBanner ">
        <div className="text-center title" data-aos="fade-down">
          <h2 >Our Core Values</h2>
          <h1 style={{color: 'white', lineHeight: '50px'}}>Upholding Excellence</h1>
        </div>
      </div>
      <div className="container" style={{paddingBlock: '50px'}}>
      <div className="amazingExpertise px-5">
        <Col xl={12} lg={12} md={12}>
              <div className="servicesExploreSolution text-center">
                <h2>
                  Be at the forefront of <span>innovation</span>
                </h2>
                <p>
                  We’re here to inform which power solutions you need.
                </p>
              </div>
            </Col>
            </div>

      </div>
      <AmazingExpertise />
      <OurPricing />
    </>
  );
}
