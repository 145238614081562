import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/Images/Choose/monitor.svg";
import Icon2 from "../../../assets/Images/Choose/Icon2.png";
import Icon3 from "../../../assets/Images/Choose/robotic-arm.svg";
import Icon4 from "../../../assets/Images/Choose/testing.svg";
import Icon5 from "../../../assets/Images/Choose/computer.svg";
import Icon6 from "../../../assets/Images/Choose/usb-cable.svg";
import Icon7 from "../../../assets/Images/Choose/test.svg";
import Icon8 from "../../../assets/Images/Choose/load_testing.svg";
import Icon9 from "../../../assets/Images/Choose/box.svg";

import CountUp from "react-countup";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ChooseUs() {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, []);
  return (
    <>
      <div className="chooseBanneImg">
        <div className=" w-100 spacing">
        <div className="container p-5" style={{backgroundColor:'rgba(0,0,0,0.5)'}}> 
          <Row className="align-items-center">
            <Col xl={12} lg={12} md={12} sm={12} data-aos="fade-up">
              <div className="titleFont" style={{zIndex: '20'}}>
                <h3 style={{color: '#ffffff'}}>Our Process</h3>
                <h2 style={{color: '#ffffff'}}>Power Solutions Manufacturing Process</h2>
              </div>
              <p className="paragraph" style={{color: '#ffffff', fontSize: '120%'}}>
              Our comprehensive Power Solutions Manufacturing Process is meticulously designed to ensure the highest standards of quality, efficiency, and reliability at every step, delivering top-notch voltage stabilizers, power suppliers, UPS systems, and more to meet your needs with precision and excellence.
              </p>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} data-aos="flip-down">
              <Row>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                  <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon1}
                    />
                    <h4>Design & Simulation</h4>
                    <p>Our experienced engineers meticulously design and simulate the voltage stabilizers, power suppliers, and UPS systems to ensure optimal performance and efficiency.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon2}
                    />
                    <h4>Planning & Procurement</h4>
                    <p>Detailed planning is undertaken to streamline the procurement process, ensuring that all necessary components and materials are sourced efficiently and on time.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon3}
                    />
                    <h4>Winding & Lamination</h4>
                    <p>Skilled technicians meticulously wind and laminate the transformers, adhering to strict quality standards. Supervised to ensure quality compliance.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon4}
                    />
                    <h4>Testing Of Transformers</h4>
                    <p>Testing of transformers ensures reliability and efficiency by validating performance and safety standards through rigorous assessment and analysis.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon5}
                    />
                    <h4>Assembly And Fitting</h4>
                    <p>The components are carefully assembled and fitted together, following precise instructions to guarantee the functionality and safety of the final product.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon6}
                    />
                    <h4>Wiring</h4>
                    <p>Our expert electricians meticulously wire the voltage stabilizers, power suppliers, and UPS systems, ensuring proper connections and adherence to safety standards.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon7}
                    />
                    <h4>No Load Testing</h4>
                    <p>Before final assembly, each unit undergoes thorough no-load testing to ensure that all components are functioning correctly and that there are no defects.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon8}
                    />
                    <h4>Load Testing</h4>
                    <p>The assembled units are subjected to rigorous load testing to simulate real-world conditions and ensure that they can handle the intended load with stability and reliability.</p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto custom-icon"
                      alt="Choose us"
                      src={Icon9}
                    />
                    <h4>Packing & Dispatch</h4>
                    <p>Units are carefully packed with high-quality materials to prevent damage & dispatched with necessary documentation and support for seamless delivery</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        </div>
      </div>
    </>
  );
}
