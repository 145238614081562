import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/Nucleus_Logo.png";
import { useState } from "react";
const Header = (props) => {
  var className = "inverted";
  const [addClass, setAddClass] = useState(" ");
  const [showDropdown, setShowDropdown] = useState(false);
  var scrollTrigger = 20;

  const onToggle = () => {
    document.getElementById("myOverlay").style.display = "block";
    document.getElementById("stamenu").className = "active";
  };

  const onCloseNav = () => {
    document.getElementById("myOverlay").style.display = "none";
    document.getElementById("stamenu").className = "";
  };

  window.onscroll = function () {
    setAddClass();
    if (
      window.scrollY >= scrollTrigger ||
      window.pageYOffset >= scrollTrigger
    ) {
      document.getElementsByTagName("nav")[0].classList.add(className);
      // setLogo("blackLogo");
    } else {
      document.getElementsByTagName("nav")[0].classList.remove(className);
      // setLogo("whiteLogo");
    }
  };

  // const [click, setClick] = React.useState(false);
  // const handleClick = () => setClick(!click);
  return (
    <>
      <div className={addClass} style={{ zIndex: '100'}}>
        <div className="header" style={{ zIndex: '100'}}>
        <div className="  sticky-top " id="stamenu">
          <Navbar expand="lg" collapseOnSelect>
            <div className="container">
              <div className="inner-header">
                <Navbar.Brand href="/">
                  <img
                    src={ThemeLogo}
                    alt="themeLogo"
                    className="img-fluid mx-auto WLogo"
                    style={{ maxHeight: '70px' }}
                  />
                  <img
                    src={ThemeLogo}
                    alt="themeLogo"
                    className="img-fluid mx-auto BLogo"
                    style={{ maxHeight: '50px' }}
                  />
                </Navbar.Brand>
                <Button
                  className="btn-navbar navbar-toggle"
                  onClick={onToggle}
                  data-toggle="collapse"
                  data-target=".nabar-ex1-collapse"
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </Button>

                <div id="mySidenav" className="sidenav menu-vertical">
                  <div className="close-nav hidden-md hidden-lg hidden-xl ">
                    <span>Menu</span>
                    <Button
                      className="closebtn pull-right"
                      onClick={onCloseNav}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Button>
                  </div>

                  <Nav className="justify-content-center"  id="myOverlay"style={{ flex: 1 }}>
                    <Nav.Link href="home">
                      <Link to="/home"> Home </Link>
                    </Nav.Link>
                    <NavDropdown
                      title="About Us"
                      id="collasible-nav-dropdown"
                      show={showDropdown}
                      onMouseLeave={() => setShowDropdown(false)}
                      onMouseOver={() => setShowDropdown(true)}
                    >
                      <NavDropdown.Item>
                        <Link to="/aboutus" >About Us</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Link to="/values">Our Values</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Link to="/home/faq">FAQ</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Link to="/inhouse">In House Services</Link>
                      </NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link>
                        <Link to="/Products">Products</Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to="/home/clients">Clients</Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to="/contact">Contact Us</Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to="/blog">Blog</Link>
                      </Nav.Link>
                  </Nav>
                </div>
                <div className="w3-overlay w3-animate-opacity"></div>
              </div>
            </div>
          </Navbar>
        </div>
      </div>
      </div>
    </>
  );
};
export default Header;
