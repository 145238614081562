import React from "react";
import { Row, Col } from "react-bootstrap";
import AmazingEx from "../../../assets/Images/Services/ExcellenceSide.webp";
// import Progress from "./Progress";

export default function AmazingExpertise() {
  const establishmentYear = 1974;
  const currentYear = new Date().getFullYear();
  const noOfYears = currentYear - establishmentYear;
  return (
    <>
      <div className="container">
        <div className="amazingExpertise">
          <Row>
            <Col xl={5} lg={5} md={6} sm={5}>
              <div className="beffect">
                <div className="bimg">
                  <img
                    className="img-fluid mx-auto"
                    src={AmazingEx}
                    alt="services"
                    data-aos="flip-up"
                  />
                </div>
              </div>
            </Col>
            <Col xl={7} lg={7} md={6} sm={7}>
              <div className="amazingColContain">
                <div className="titleFont">
                  <h3>Amazing Expertise</h3>
                  <h2>{noOfYears} years of legacy</h2>
                </div>
                <div className="scrollbar">
                  <div>
                    <p>At Nucleus Electro Enterprises, our every action is driven by an unwavering commitment to quality, innovation, and customer satisfaction, ensuring superior products and exceptional service for our valued clients.</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
