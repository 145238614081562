import React from "react";
import "../../../../src/theme/css/HomePage.css";
// import { Row, Col } from "react-bootstrap";
// import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
// import { Link } from "react-router-dom";
import Banner from "../../../assets/Images/Banner/Banner.jpg";
// import Banner from "../../../assets/Images/Banner/Banner1.jpg";
// import Aos from "aos";
import "aos/dist/aos.css";
// import { Link } from "react-router-dom";

export default function Home() {
  return (
    
    <>
      <div className="mainBannerDiv bg-overlay">
        <img className="mx-auto" src={Banner} alt="BannerImage" style={{ width: '100%' }} />
        <div className="container">
          <div className="bannerContain text-center" data-aos="zoom-in" style={{ zIndex: '2'}}>
            <div className="titleFont">
              <h3 style={{color: '#00FF00'}}>Nucleus Electro Enterprises</h3>
            </div>
            <h1><span>Powering</span> Your Business, Empowering Your <span>Progress</span></h1>
            <h5>
            Fueling innovation with cutting-edge solutions, we ignite your path to success.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
