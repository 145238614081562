import React from 'react'
import '../../../theme/css/Portfolio.css'
import NucleusProducts from './NucleusProducts';
import PortfolioBanner from '../../../assets/Images/Portfolio/portFolioBanner.webp'
import Contact from '../HomePage/Contact/Contact';
import InHouseDetails from './InHouseDetails';
// import Products from './../HomePage/Products1';
export default function InHouse() {
    return (
        <>
        <div className="mainBannerDiv bg-overlay">
        <img className="mx-auto" src={PortfolioBanner} alt="BannerImage" style={{ width: '100%' }} />
        <div className="container">
          <div className="bannerContain text-center" data-aos="zoom-in" style={{ zIndex: '2'}}>
            <div className="titleFont">
              <h3 style={{color: '#00FF00'}}>Nucleus Electro Enterprises</h3>
              {/* <h3 style={{color: '#ffffff'}}>Our In House Facility</h3> */}
            </div>
            <h1>Our In House Facility</h1>
          </div>
        </div>
      </div>
        <div className='spacing m-5'>
          <p className="text-justify">At Nucleus Electro Enterprises, our commitment to customer satisfaction extends far beyond the point of purchase. Our exceptional after-sales service ensures that you receive the support you need long after your product is in use. Whether you require assistance with installation, troubleshooting, or maintenance, our dedicated team is here to provide timely and efficient solutions. With our unwavering support, you can trust in the reliability and longevity of your power solutions, backed by our commitment to your satisfaction.</p>
        </div>
          <hr />
        {/* <NucleusProducts/> */}
        <InHouseDetails />
        <hr />
        <Contact/>
      </>
    )
}
