import React, { useState, useEffect } from "react";
import "../../../theme/css/Portfolio.css";
import { Card } from "react-bootstrap";
import Inhouse1 from '../../../assets/Images/NucleusInhouse/1.jpg'
import Inhouse2 from '../../../assets/Images/NucleusInhouse/2.jpg'
import Inhouse3 from '../../../assets/Images/NucleusInhouse/3.jpg'
import Inhouse4 from '../../../assets/Images/NucleusInhouse/4.jpg'
import Inhouse5 from '../../../assets/Images/NucleusInhouse/5.jpg'
import Inhouse6 from '../../../assets/Images/NucleusInhouse/6.jpg'
import Inhouse7 from '../../../assets/Images/NucleusInhouse/7.jpg'
import Inhouse8 from '../../../assets/Images/NucleusInhouse/8.jpg'
import Inhouse9 from '../../../assets/Images/NucleusInhouse/9.jpg'
import Inhouse10 from '../../../assets/Images/NucleusInhouse/10.jpg'
import Inhouse11 from '../../../assets/Images/NucleusInhouse/11.jpg'
import Inhouse12 from '../../../assets/Images/NucleusInhouse/12.jpg'
import Inhouse13 from '../../../assets/Images/NucleusInhouse/13.jpg'
import Inhouse14 from '../../../assets/Images/NucleusInhouse/14.jpg'
import Inhouse15 from '../../../assets/Images/NucleusInhouse/15.jpg'
import Inhouse16 from '../../../assets/Images/NucleusInhouse/16.jpg'
import Inhouse17 from '../../../assets/Images/NucleusInhouse/17.jpg'
import Inhouse18 from '../../../assets/Images/NucleusInhouse/18.jpg'

// import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Row, Col } from "react-bootstrap";

function InHouseDetails() {
  useEffect(() => {
    Aos.init({ duration: 3500 });
  }, []);
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
    <div className="container portfolio">
      <div className="bloc-tabs p-1">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          <span className="in-house">Design & Simulation</span>
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          <span className="in-house">Planning & Procurement</span>
        </button>
        <button
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          <span className="in-house">Winding & Lamination</span>
        </button>
        <button
          className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(4)}
        >
          <span className="in-house">Assembly & Fitting</span>
        </button>
        <button
          className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(5)}
        >
          <span className="in-house">Wiring</span>
        </button>
        <button
          className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(6)}
        >
          <span className="in-house">No Load Testing</span>
        </button>
        <button
          className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(7)}
        >
          <span className="in-house">Load Testing</span>
        </button>
        <button
          className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(8)}
        >
          <span className="in-house">Packing & Dispatch</span>
        </button>
        <button
          className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(9)}
        >
          <span className="in-house">MSME Certification</span>
        </button>
      </div>
    </div>
    <div className="container portfolio">
    <div className="content-tabs">
        <div className={toggleState === 1 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse1} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse2} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className={toggleState === 2 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse3} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse4} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className={toggleState === 3 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse5} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse6} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse7} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className={toggleState === 4 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse8} />
                  </div>
                </Card>
              </Col>
              {/* <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse2} />
                  </div>
                </Card>
              </Col> */}
            </Row>
          </div>
        </div>
        <div className={toggleState === 5 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse9} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse10} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className={toggleState === 6 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse11} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse12} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className={toggleState === 7 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse13} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse14} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className={toggleState === 8 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse15} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse16} />
                  </div>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse17} />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className={toggleState === 9 ? "content  active-content" : "content"}>
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse18} />
                  </div>
                </Card>
              </Col>
              {/* <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Inhouse2} />
                  </div>
                </Card>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default InHouseDetails;
