import React, { useEffect } from "react";
// import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import aboutus from "../../../assets/Images/AboutUs/Nucleus.jpg";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";



export default function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const currentYear = new Date().getFullYear();
  const yearsSince1974 = currentYear - 1974;
  return (
    <>
      <div className="container">
        <div className="aboutus spacing">
          <Row className="aboutRow">
            <Col xl={4} lg={4} md={4} sm={3} xs={12}>
              <img
                className="img-fluid mx-auto"
                alt="AboutUs"
                src={aboutus}
                data-aos="flip-down"
              />
            </Col>

            <Col xl={8} lg={8} md={8} sm={9} xs={12} data-aos="fade-up">
              <div className="titleFont">
                <h3>About Nucleus Electro Enterprises</h3>
                <h2>Providing Power Solutions for your Business Needs for over {yearsSince1974} years</h2>
              </div>
              <p className="paragraph">
              Welcome to Nucleus Electro Enterprises, your premier destination for cutting-edge power solutions. Established in 1974, we have been a trusted name in the industry, serving clients with excellence from our location in Bhopal, Madhya Pradesh, India. Specializing in the manufacturing and distribution of voltage stabilizers, power supplies, UPS systems, and related power products, we pride ourselves on delivering top-quality, reliable solutions tailored to meet your unique needs. With a focus on innovation, durability, and customer satisfaction, Nucleus Electro Enterprises is your go-to source for premium stabilizers and UPS systems. Explore our range today and power up with confidence.
              </p>
              <div className="aboutUsPoint">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Customized Solutions</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Innovative Technology</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Exceptional Quality Assurance</span>
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Customer-Centric Approach</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <button className="button">
                <Link to="/service">More About Us </Link>
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
