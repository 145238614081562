import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../theme/css/Blog.css";
import OurBlogData from "../HomePage/OurBlogData";

export default function NucleusBlog() {
  return (
    <>
    
      <div className="otherPageBanner BlogPageBanner">
        <div className="text-center title" style={{paddingTop:'10vh'}}>
          <h2>LATEST BLOG</h2>
          <h6>Nucleus Eletro leaders vision blog</h6>
        </div>
      </div>
      <div className="container">
        <div className="spacing blogPage ">
          <Row>
            {OurBlogData.map((blogdata) => (
              <Col xl={4} lg={4} md={6} sm={12}>
                <div className="blogCardMainClass blog">
                  <div className="mainBlog " key={blogdata.id}>
                    <div className="recent">
                      <Link to={`/blogpage/${blogdata.id}`}>
                        <img
                          className="img-fluid mx-auto"
                          variant="top"
                          src={blogdata.image}
                          alt="blog"
                        />
                        <div className="blog-post-hover">
                          <div className="blog-icon">
                            <div className="instaicon ">
                              <i class="fa fa-search"></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="mainBlogBody">
                      <h2> {blogdata.title} </h2>
                      <div className="blogCardBody">
                      </div>
                      <hr />
                      <p className="paragraph"> {blogdata.contain} </p>{" "}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}
