import React from "react";
import { useParams } from 'react-router-dom';
import OurBlogData from "../HomePage/OurBlogData";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pattern from "../../../assets/Images/Blog/protruding-squares.svg";



export default function NucleusBlogPage() {
  const { id } = useParams();

  const blogPost = OurBlogData.find((blog) => blog.id === parseInt(id));

  if (!blogPost) {
    return (
      <div>
        <Link to="/" >Go to Home</Link>
      </div>
    );
  }

  return (
    <>
          <div className="otherPageBanner BlogPageBanner">
        <div className="text-center title" style={{paddingTop:'10vh'}}>
          <h2>LATEST BLOG</h2>
          <h6>Nucleus Eletro leaders vision blog</h6>
        </div>
      </div>
    
    <div className="blogpage">
      <div className="container">
        <div className="spacing">
              <div className="blogpageFstContainBlog">
          <Row>
                <h2>{blogPost.title}</h2>
                <div className="d-flex justify-content-center">
                  <img
                      className="img-fluid mx-auto blogpost-image-ht align-middle"
                      variant="top"
                      src={blogPost.image}
                      alt="blogpage"
                    />
                </div>
                  <h5>{blogPost.subtitle1}</h5>
                  <p className="paragraph">{blogPost.subpara1}</p>
                  <hr />
            <Col xl={8} lg={8} md={8} sm={12}>
              <h5>{blogPost.subtitle2}</h5>
              <p className="paragraph">{blogPost.subpara2}</p>
              <hr />
            </Col>
            <Col xl={4} lg={4} md={4} sm={12}>
              <div className="flex-container">
                <img className="blogpost-pattern" src={Pattern} alt="image" />
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={12}>
              <div className="flex-container">
                <img className="blogpost-pattern" src={Pattern} alt="image" />
              </div>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12}>
              <h5>{blogPost.subtitle3}</h5>
              <p className="paragraph">{blogPost.subpara3}</p>
              <hr />
            </Col>
            <Col xl={8} lg={8} md={8} sm={12}>
              <h5>{blogPost.subtitle4}</h5>
              <p className="paragraph">{blogPost.subpara4}</p>
              <hr />
            </Col>
            <Col xl={4} lg={4} md={4} sm={12}>
              <div className="flex-container">
                <img className="blogpost-pattern" src={Pattern} alt="image" />
              </div>
            </Col>          
          </Row>
          <h5>{blogPost.conclusion_title}</h5>
          <p className="paragraph">{blogPost.conclusion_para}</p>
        </div>
        </div>
      </div>
    </div>
    </>
  );
}
