import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AboutMenuBlog from "../../../assets/Images/AboutUs/AboutMenuBlog.png";
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
// import AboutUsVideo from "../AboutUs/AboutUsVideo";
import aboutus from "../../../assets/Images/AboutUs/Nucleus.jpg";
import aboutus1 from "../../../assets/Images/AboutUs/AboutUs1.webp";
import aboutusImg1 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";
import aboutusImg3 from "../../../assets/Images/AboutUs/aboutusImg3.jpg";
import aboutusImg4 from "../../../assets/Images/AboutUs/aboutusImg4.jpg";
import OurTeam from "../HomePage/OurTeam";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import Brandlogo from "../HomePage/Brandlogo";
import OurPricing from "../HomePage/OurPricing";
import WeProvide from "../HomePage/WeProvide";
export default function AboutUsMenu() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const settings = {
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center">
          <div className="spacing"></div>
          <h2 style={{color:'#00ff00'}}>ABOUT NUCLEUS ELECTRO ENTERPRISES</h2>
          <h6>Igniting Innovation, Empowering Your Success</h6>
          <div className="spacing"></div>
        </div>
      </div>
      <div className="container">
        <div className="aboutMenu ">
          <Row>
            <Col xl={6} lg={6} md={6} sm={7}>
               <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutus}
                />
            </Col>
            <Col xl={6} lg={6} md={6} sm={5} className="colForResponsive">
              <div className="titleFont">
                <h3>ABOUT US</h3>
                <h2>Unleash the Power Within, Empower Your Tomorrow</h2>
              </div>
              <p>
              Welcome to Nucleus Electro Enterprises, your premier destination for cutting-edge power solutions. Established in 1974, we have been a trusted name in the industry, serving clients with excellence from our location in Bhopal, Madhya Pradesh, India. Specializing in the manufacturing and distribution of voltage stabilizers, power supplies, UPS systems, and related power products, we pride ourselves on delivering top-quality, reliable solutions tailored to meet your unique needs. With a focus on innovation, durability, and customer satisfaction, Nucleus Electro Enterprises is your go-to source for premium stabilizers and UPS systems. Explore our range today and power up with confidence.
              </p>
            </Col>
          </Row>
          <Row>
            <div className="spacing"></div>

            <Col xl={7} lg={7} md={7} sm={5} className="colForResponsive">
              <div className="titleFont">
                <h3>Mission and Vission</h3>
                <h2>Transforming Power Solutions for a Sustainable Future</h2>
              </div>
              <p>
              At Nucleus Electro Enterprises, our mission is to revolutionize the power solutions industry by delivering cutting-edge products and exceptional service. Our vision is to empower individuals and businesses alike with reliable, innovative, and sustainable power solutions that exceed expectations. We strive to be at the forefront of technological advancements, consistently pushing the boundaries to meet evolving needs and challenges. With a relentless commitment to excellence, we aim to make a lasting impact on the world of power solutions, driving progress and prosperity for our customers and communities.
              </p>
            </Col>
            <Col xl={5} lg={5} md={5} sm={7}>
            <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-right"
                  src={aboutus1}
                />
            </Col>
          </Row>
        </div>
      </div>
      <div className="spacing"></div>
      {/* <OurPricing /> */}
      <WeProvide />
    </>
  );
}
