import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/Nucleus_Logo.png";

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);

  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox">
            <Col xl={5} lg={5} md={4} sm={12}>
              <div className="F-col-1 footer-text">
                <img
                  src={ThemeLogo}
                  alt="themeLogo"
                  className="img-fluid mx-auto WLogo"
                  style={{maxHeight: '150px'}}
                />
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
            </Col>
            <Col xl={2} lg={2} md={2}>
            </Col>
          
            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">Contact Us 
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                    103, Nucleus House, Berkhedi Road, Jahangirad, Near Extol College, Bhopal M.P.

                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+91 9425010238</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>vivek7b@yahoo.com</span>
                  </div>
                </div>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
      <h6 className="F-copywrite">Copyright &copy; {new Date().getFullYear()} Nucleus Electro Enterprises. All Rights Reserved</h6>
      <h6 className="F-copywrite" style={{fontSize:'60%'}}> Designed and Managed with &#9829; by WebMagnetx</h6>
      </div>
    </>
  );
};
export default Footer;
